import React from "react";
import { Component } from 'react';

class Match extends Component {

    state = {
        updatedInfo: false
    }

    saveChange = () => {
        let savedPlayerAvail = this.props.match.playerAvail

        if (this.props.selectedPlayer) {
            if (this.props.match.playerAvail) {
                let index = this.props.match.playersCompleted.indexOf(this.props.selectedPlayer);
                this.props.match.playersCompleted.splice(index, 1);
            } else {
                this.props.match.playersCompleted.push(this.props.selectedPlayer);
            }

            this.props.saveUpdate(this.props.match, savedPlayerAvail);
            this.setState({ updatedInfo: true });
        }
    }

    formatMatchClass = () => {
        let matchClassName = 'match ';
        if (this.props.match.playerAvail) matchClassName += ' available';
        else
            if (this.props.match.playersCompleted.length / this.props.numPlayers >= .8) matchClassName += ' match-complete';

        return matchClassName;
    }

    render() {
        return (
            <div>
                <div className={this.formatMatchClass()} onClick={this.saveChange}>
                    <div className="instructor-column">
                    <img src={this.props.match.image_url} title={this.props.match.name} alt={this.props.match.name} />
                     <div className="match-first">{`${this.props.match.first_name}`}</div>
                    </div>
                    {/* <div className="row">
                        <h3 className="">{this.props.match.item}</h3>
                    </div> */}
                    {/* <div className="match-first">{`${this.props.match.last_name} - ${this.props.match.first_name}`}</div> */}
                    <div className="col">
                        {this.props.match.playersCompleted.map(player => <div className="">{player}</div>)}


                        {/* {this.props.match.playersCompleted.sort().map(player => `${player} `)} */}

                    </div>

                    {/* <div className={(this.props.match.scheduled.length > 0) ? "match-line" : "hide"}>
                  <span className={(this.props.match.scheduled.indexOf(this.props.selectedPlayer)>=0) ? "scheduled" : ""}> 
                  <span className="title">Scheduled: </span>{this.props.match.scheduled.sort().map(player => `${player} `)}</span> 
                </div> */}
                </div>
                {/* <div className={(this.props.x % 2 === 0) ? "vs" : "hide"}>vs</div>
                <div className={(this.props.x % 2 === 0) ? "hide" : "draw-line"}></div> */}
            </div>
        )
    }
}
export default Match;